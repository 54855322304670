import React from 'react'
import avatarImg from '../assets/avatar.png'
import { AiOutlineHeart, AiOutlineComment,AiOutlineShareAlt} from 'react-icons/ai'

const AboutMe = () => {
  return (
    <div className="p-4 mt-8">
      <div className="max-w-4xl lg:max-w-[90rem] mx-auto bg-fgGreen rounded-2xl border-4 border-black p-6" style={{ boxShadow: '5px -5px 0px 0px black' }} id="about-me-section">
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6">
          <div className="flex-1  ">
            <div className=' inline-block'>
              <div className="bg-fgPurple rounded-2xl shadow-md border-4 border-black py-4 px-12" style={{ boxShadow: '-3px 3px 0px 0px black' }}>
                <h2 className="text-white text-lg md:text-3xl text-center font-semibold">Who's this guy?</h2>
              </div>
            </div>

            <p className="text-white text-base md:text-xl font-bold my-8 font-clash-display-semibold">

            I hold a BSc in Game Design and Development and am eager to use my previous experiences, knowledge, and skills to fit into the role of a game designer and create something prodigious and worth remembering.
            </p>
            <p className="text-white text-base md:text-xl font-bold my-8 font-clash-display-semibold">
            As a detail-oriented game designer with almost


            <span className='text-black underline'> 3 years of hands-on experience</span> in developing <span className='text-black underline'> FPS, Open world, casual, hyper-casual, and board games, </span> I have released <span className='text-black underline'> 9 titles</span> that have been enjoyed by <span className='text-black underline'> hundreds of thousands of players</span>. I am passionate about<span className='text-black underline'> making an impact in the gaming industry </span> and my latest experience includes <span className='text-black underline'> leading a team </span> of talented people along with my deep knowledge of gaming trends, storytelling ability, creativity, critical thinking, analytical thinking, and logical thinking to create a <span className='text-black underline'> AA open-world RPG game for Web 3.0 </span>.
            </p>
          </div>
          

          
        </div>
      </div>
    </div>
  )
}

export default AboutMe
