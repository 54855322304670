import React from "react";
import reactDom from "react-dom/client";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { LandingPage } from "./components/LandingPage";
import Header from './components/Header';
import Contribution1 from './components/contributions/Contribution1';
import Contribution2 from "./components/contributions/Contribution2";
import Contribution3 from "./components/contributions/Contribution3";
import Contribution4 from "./components/contributions/Contribution4";
import Contribution5 from "./components/contributions/Contribution5";
import Contribution6 from "./components/contributions/Contribution6";
import Contribution7 from "./components/contributions/Contribution7";
import Contribution8 from "./components/contributions/Contribution8";
import Contribution9 from "./components/contributions/Contribution9";

const root = reactDom.createRoot(document.getElementById("root"));

const App = () => {
    return (
        <div>
            <Header />
            <Outlet/>
        </div>
    );
};

const appRouter = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '/',
                element: <LandingPage />
            },
            {
                path: '/contribution1',
                element: <Contribution1 />
            },
            {
                path: '/contribution2',
                element: <Contribution2/>
            },
            {
                path: '/contribution3',
                element: <Contribution3/>
            },
            {
                path: '/contribution4',
                element: <Contribution4/>
            },
            {
                path: '/contribution5',
                element: <Contribution5/>
            },
            {
                path: '/contribution6',
                element: <Contribution6/>
            },
            {
                path: '/contribution7',
                element: <Contribution7/>
            },
            {
                path: '/contribution8',
                element: <Contribution8/>
            },
            {
                path: '/contribution9',
                element: <Contribution9/>
            }
        ]
    }
]);

root.render(
    <RouterProvider router={appRouter}>
        <App />
    </RouterProvider>
);
