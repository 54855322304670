import React from "react";
import { Link } from "react-scroll";
import experiences from "../utils/ExperienceDB";

const Experience = () => {

  return (
    <div className="p-4 mt-8">
      <div
        className="max-w-fit md:max-w-4xl lg:max-w-[110rem] mx-auto bg-fgPurple rounded-2xl border-4 border-black p-6"
        style={{ boxShadow: "5px -5px 0px 0px black" }}
        id="experience-section"
      >
        <div className="inline-block mb-8">
          <div
            className="bg-fgYellow rounded-2xl shadow-md border-4 border-black py-4 px-12"
            style={{ boxShadow: "-3px 3px 0px 0px black" }}
          >
            <h2 className="text-lg md:text-3xl text-center font-semibold">
              Experience
            </h2>
          </div>
        </div>

        <div className="max-h-[700px] overflow-y-scroll">
          {experiences.map((exp) => (
            <div key={exp.id} className="my-8 ml-6 flex flex-col mr-4">
              <div className="flex">
                <img
                  src={exp.companyLogo}
                  alt={`Logo of ${exp.companyName}`}
                  className="mr-6 h-12 w-12 md:h-[5rem] md:w-[5rem] rounded-full object-cover border-black border-4"
                />
                <div>
                  <h1 className="font-clash-display-semibold text-base md:text-xl m-0 p-0 leading-5">
                    {exp.companyName}
                  </h1>
                  <h2 className="font-clash-display-medium opacity-70 text-xs md:text-lg m-0 p-0 leading-5">
                    Position: {exp.position}
                  </h2>
                </div>
              </div>

              <div>
                <div
                  className="md:max-w-[90%] mt-4 md:ml-16 mr:8 bg-white rounded-2xl border-4 border-black p-6"
                  style={{ boxShadow: "3px -3px 0px 0px black" }}
                >
                  <div className="text-xs md:text-xl lg:text-2xl font-bold my-4 md:my-6 font-clash-display-semibold">
                    Responsibilities:
                  </div>
                  {exp.responsibilities.map((responsibility, index) => (
                    <p
                      key={index}
                      className="text-xs md:text-xl lg:text-2xl font-bold my-4 md:my-6 font-clash-display-semibold m-0 p-0 lg:leading-5"
                    >
                      • {responsibility}
                    </p>
                  ))}
                  <div className="text-xs md:text-xl lg:text-2xl font-bold my-4 md:my-14 font-clash-display-semibold">
                    Key Achievements:
                    {exp.achievements.map((achievement, index) => (
                    <p
                      key={index}
                      className="text-xs md:text-xl lg:text-2xl font-bold my-4 md:my-6 font-clash-display-semibold m-0 p-0 lg:leading-5"
                    >
                      • {achievement}
                    </p>
                  ))}
                  </div>
                </div>
                <p className=" text-xs md:text-lg text-right mt-4 mr-20 lg:mr-40 font-clash-display-medium opacity-70">
                 {exp.date}
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* Comment section and MyContributions Box */}
        <div className="flex justify-normal gap-5 p-2 mt-8">
          <div className={`bg-fgPurple2 rounded-2xl py-4 px-12 w-full md:w-[40rem] lg:w-[80rem]`}>
            <h2 className={`text-white text-xl md:text-xl font-clash-display-semibold`}></h2>
          </div>

          <Link
            activeClass="active"
            to="my-works-section"
            spy={true}
            smooth={true}
            offset={-70}
            duration={900}
          >
            <div
              className="inline-block bg-fgYellow rounded-2xl shadow-md border-4 border-black p-4 md:py-4 md:px-12"
              style={{ boxShadow: "-3px 3px 0px 0px black" }}
            >
              <h2 className="whitespace-nowrap text-sm md:text-3xl text-center font-clash-display-semibold">
                My Contribution
              </h2>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Experience;
