import React from 'react';
import { Link } from 'react-scroll';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const isContributionPage = /\/contribution\d+/.test(location.pathname);

  return (
    <header className="flex justify-between items-center py-4 mt-6 mb-10">
      {isContributionPage ? (
        <RouterLink to="/" className=" md:ml-6 inline-block bg-fgPink text-white font-clash-display-semibold text-sm md:text-3xl rounded-lg md:rounded-2xl border-2 border-black 
        p-2 md:py-4 md:px-12 ml-6 md:ml:0"
              style={{ boxShadow: "3px 3px 0px 0px black" }}
            >
          BACK
        </RouterLink>
      ) : (
        <div></div>
      )}
      <div className="flex justify-center">
        <Link
          activeClass="active"
          to="about-me-section" 
          spy={true}
          smooth={true}
          offset={-70}
          duration={900}
        >
          <h2 className="font-clash-display-semibold text-fgPink text-sm md:text-2xl mx-4 tracking-tight">
            About Me
          </h2>
        </Link>
        <Link
          activeClass="active"
          to="my-works-section" 
          spy={true}
          smooth={true}
          offset={-70}
          duration={900}
        >
          <h3 className="font-clash-display-semibold text-sm md:text-2xl mx-4 tracking-tight">
            My Works
          </h3>
        </Link>
        <Link
          activeClass="active"
          to="experience-section" 
          spy={true}
          smooth={true}
          offset={-70}
          duration={900}
        >
          <h3 className="font-clash-display-semibold text-sm md:text-2xl mx-4 tracking-tight">
            Experience
          </h3>
        </Link>
      </div>
      <div></div>
    </header>
  );
};

export default Header;
