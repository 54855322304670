import React from 'react';
import Header from './Header';
import HeroSection from './HeroSection';
import AnimBanner from './AnimBanner';
import AboutMe from './AboutMe';
import MyWorks from './MyWorks';
import Experience from './Experience';

export const LandingPage = () => {
  return (
    <div>
      <HeroSection />
      <AnimBanner textColor="text-fgPink" bgColor="bg-fgPink" text="About Me" />
      <AboutMe />
      <AnimBanner textColor="text-fgPurple" bgColor="bg-fgPurple" text="My Works" />
      <MyWorks />
      <AnimBanner textColor="text-fgGreen" bgColor="bg-fgGreen" text="Experience" />
      <Experience />
    </div>
  );
};
