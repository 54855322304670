const AnimBanner = ({ textColor,bgColor, text }) => {
  // Define the number of spans to generate
  const numSpans = 10;

  // Create an array of numbers from 0 to numSpans -1
  const spanIndices = [...Array(numSpans).keys()];

  // Clone the spanIndices array by concatenating it with itself
  const clonedSpanIndices = [...spanIndices, ...spanIndices];

  return (
      <div className={` overflow-hidden relative ${bgColor} text-white py-4 mt-9 lg:max-w-screen-large`}>
          <div className="absolute top-0 left-0 w-full border-t-4 border-black"></div>
          <div className="absolute bottom-0 left-0 w-full border-b border-black"></div>
          <div className="flex flex-col items-center space-y-2">
              <div className="flex space-x-12 md:space-x-48 animate-slide-right-to-left">
                  {clonedSpanIndices.map((i) => (
                      <span key={`group1-${i}`} className={`whitespace-nowrap text-lg md:text-3xl ${textColor} text-stroke-sm anim-text-stroke`}>{text}</span>
                  ))}
              </div>
              <div className="flex space-x-12 md:space-x-48 animate-slide-left-to-right">
                  {clonedSpanIndices.map((i) => (
                      <span key={`group2-${i}`} className=' whitespace-nowrap text-lg md:text-2xl font-bold text-black font-clash-display-bold'>{text}</span>
                  ))}
              </div>
              <div className="flex space-x-12 md:space-x-48 animate-slide-right-to-left">
                  {clonedSpanIndices.map((i) => (
                      <span key={`group3-${i}`} className={`whitespace-nowrap text-lg md:text-3xl font-black ${textColor} text-stroke-sm anim-text-stroke`}>{text}</span>
                  ))}
              </div>
          </div>
      </div>
  )
}

export default AnimBanner