import React from 'react';
import myWorks from '../utils/MyWorksDB';
import '../custom-scroll-bar.css';
import { Link } from 'react-router-dom';

const MyWorks = () => {
  return (
    <div className='p-4 mt-8'>
      <div className="max-w-fit md:max-w-4xl lg:max-w-[110rem] mx-auto bg-fgPurple rounded-2xl border-4 border-black p-6" style={{ boxShadow: '5px -5px 0px 0px black' }} id="my-works-section">
        <div className='inline-block'>
          <div className="bg-fgYellow rounded-2xl shadow-md border-4 border-black py-4 px-12" style={{ boxShadow: '-3px 3px 0px 0px black' }}>
            <h2 className=" text-lg md:text-3xl text-center font-semibold">Games</h2>
          </div>
        </div>
        <div className="max-w-full overflow-x-auto mt-8 flex flex-nowrap pb-4">
          {myWorks.map((work) => (
            <div key={work.id} className="flex-shrink-0 bg-white rounded-[1.3rem] shadow-md border-4 border-black p-3 flex flex-col relative w-full md:w-80 mx-4">
              <div className="flex items-start justify-end mb-2 mt-4">
                <div className="flex flex-col">
                  <h3 className="text-black text-base md:text-lg font-bold font-clash-display-semibold">{work.title}</h3>
                  <p className="text-gray-500 text-sm md:text-md ml-10 font-clash-display-medium">{work.price}</p>
                </div>
                <img src={work.avatarImg} alt="Avatar" className="w-10 h-10" />
              </div>
              <div className="flex-grow relative">
                <div className="relative">
                  <img src={work.gameImg} alt="Game" className="w-full h-[50%] md:h-[60%] object-cover mb-2 mt-[-20%]" />
                  <Link to={`/contribution${work.id}`}>
                  <div className={`whitespace-nowrap absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-fgYellow rounded-lg shadow-md border-4 border-black mb-2 py-2 px-4 text-center`}>
                    <p className={`text-black text-base font-bold`}>{work.contributions}</p>
                  </div></Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyWorks;