import placeholderAvatar from '../assets/placeholderAvatar.png';
import ewarLudo from '../assets/ludoPlus.png';
import sheepFight from '../assets/sheepFight.png' 
import knifehit from '../assets/Knifehit.png'
import snakes from '../assets/snake and ladders.png'
import Eightball from '../assets/8ballpool.png'
import Carrom from '../assets/Carrom.png'
import Bluff from '../assets/Bluff.png'
import BasketBall from '../assets/Basketball.png'
import metalabLogo from '../assets/metalabLogo.jpg'
import obverse from "../assets/obverseCard.png"

const myWorks = [
  {
    id: 1,
    title: "Obverse",
    price: "Production",
    avatarImg: metalabLogo,
    gameImg: obverse,
    contributions: "My Contributions",
  },
  {
    id: 2,
    title: "Ewar Ludo",
    price: "Released",
    avatarImg: placeholderAvatar,
    gameImg: ewarLudo,
    contributions: "My Contributions",
  },
  {
    id: 3,
    title: "Sheep Fight",
    price: "Released",
    avatarImg: placeholderAvatar,
    gameImg: sheepFight,
    contributions: "My Contributions",
  },
  {
    id: 4,
    title: "Basketball",
    price: "Released",
    avatarImg: placeholderAvatar,
    gameImg: BasketBall,
    contributions: "My Contributions",
  },
  {
    id: 5,
    title: "Carrom",
    price: "Released",
    avatarImg: placeholderAvatar,
    gameImg: Carrom,
    contributions: "My Contributions",
  },
  {
    id: 6,
    title: "S&L",
    price: "Released",
    avatarImg: placeholderAvatar,
    gameImg: snakes,
    contributions: "My Contributions",
  },
  {
    id: 7,
    title: "Knife Hit",
    price: "Released",
    avatarImg: placeholderAvatar,
    gameImg: knifehit,
    contributions: "My Contributions",
  },
  {
    id: 8,
    title: "Bluff",
    price: "Released",
    avatarImg: placeholderAvatar,
    gameImg: Bluff,
    contributions: "My Contributions",
  },
  {
    id: 9,
    title: "8Ball Pool",
    price: "Released",
    avatarImg: placeholderAvatar,
    gameImg: Eightball,
    contributions: "My Contributions",
  }
];

export default myWorks;
