import ewarLogo from "../assets/ewarLogo.jpg";
import hereAfter from "../assets/hereafterLogo.jpg";
import gritbyteLogo from "../assets/gritbyteLogo.jpg";
import metalabLogo from "../assets/metalabLogo.jpg";
import companyLogo from "../assets/companyLogo.jpg";

const experiences = [
  {
    id: 1,
    companyLogo: hereAfter,
    companyName: "Hereafter Studios",
    position: "Unreal Engine Developer/ 3D Artist - Intern",
    responsibilities: [
      "Managed Team Members on the given tasks to provide optimal workflow and outcome",
 "Researched and documented Human Behaviour and different personalities, walk styles and stride length ",
      "Worked on internal VR Game using Unreal Engine and built Dynamic personality system with integrated walk styles, gait and stride based on height and related animation BlendSpaces ",
      " Worked on Front end and built Game Launcher using ElectronJS, HTML and CSS ",
" Researched and worked collectively on MeshMergeFunctionLibrary for reducing draw calls in Unreal Engine for custom character mesh ",
"  Internal Application Quality Testing, Bug Reporting and Squashing "
          ],
    achievements: [
      "Quickly learned ElectronJS and helped deliver excess projects",
    ],
    date: "From Oct 2020 to Feb 2021"
  },
  {
    id: 2,
    companyLogo: ewarLogo,
    companyName: "Ewar Games",
    position: "Game Designer",
    responsibilities: [
      "Worked in Live-ops and delivered new features, updates and content for live services. ",
      "Designed various in house games focusing on Gameplay, Level Design, UI/UX, in-Hand Feel, Looks, Features, ",
      "Balancing and Game Audio Curation and Implementation. ",
      "Creation of Game Development Pipeline that works best for the Organization and Optimization for Better workflow and Outcome ",
      "Created and presented new Game Environment Document Format and Documented it as required. ",
      "Created concise game documents, presentations, wireframes, user flow etc. ",
      "Researched on different games and wrote case study with screenshots and its features to improve in house games.",
      "Co-Wrote Technical Document related to in house API Integrations and code used. ",
      "Tested in-House Games Pre-Release for bug finding and squashing ",
      "Collected User Statistics and checked for viabilities into current projects ",
      "Reskinned Game to go with current Organization App Design "
    ],
    achievements: [
      "Designed 9 Released Game Titles for Mobile from Casual and Hyper Casual game genres in 10months. ",
      "Pitched Play Tested and got Applauded for original games that were taken forward in production."
    ],
    date: "From April 2021 to March 2022"
  },
  {
    id: 3,
    companyLogo: companyLogo,
    companyName: "Gaming Monkey Pvt Ltd",
    position: "Game Design Consultant",
    responsibilities: [
      "Authored and Maintained Game Design Documents",
      "Worked with Developers and Back End Engineers to implement and gather relevant analytics data from game ",
      " Designed and Tuned games to create rich player experiences ",
" Closely participated in internal testing of games to focus on user experience and increase player retention ",
" Designed and rehauled Companion App Design with latest design trends "
          ],
    achievements: [
      " Invited and Promoted from Game Design Consultant to Full Time Game Designer ",
      "Previously Designed games were bought in-house to work further upon"
    ],
    date: "From Jan 2022 to April 2022"
  },
  {
    id: 4,
    companyLogo: metalabLogo,
    companyName: "Metalab Technologies",
    position: "Game Designer",
    responsibilities: [
      "Creative Lead behind Web 3.0 based AA Openworld RPG Game ",
      "Authored and Co-wrote Game Story with Narrative Events ",
      " Authored and maintained Game Design Document ",
" Directing Visual and Narrative Style of game ",
" Created Combat Encounters, Points of Interest, Game Consumables within the game world and locations ",
"  Collaborated with partners to ensure coherent and cohesive player experience for Technical Demo "
          ],
    achievements: [
      " Directing one of a kind AA Open World game for the Web 3.0 ",
      "Implemented Narrative Design and Game Story to bring life to the game and create engaging player experiences"
    ],
    date: "From April 2022 to Sept 2023"
  }
];

export default experiences;
