import React from "react";
import companyLogo from "../../assets/company1.png";
import avatarLogo from "../../assets/avatar.png";
import { HashLink as Link } from 'react-router-hash-link';
import o1 from "../../assets/contr6/C6O1.png"
import o2 from "../../assets/contr6/C6O2.jpg"
import o3 from "../../assets/contr6/C6O3.png"

const Contribution6 = () => {
  return (
    <div className="p-4 mt-8">
      <div
        className=" max-w-max md:max-w-[115rem] mx-auto bg-fgPurple rounded-2xl border-4 border-black p-6"
        style={{ boxShadow: "5px -5px 0px 0px black" }}
        id="experience-section"
      >
        <div className=" bg-fgPurple2 flex flex-col items-center justify-center rounded-t-2xl mb-4">
          <iframe
            src="https://drive.google.com/file/d/1sMYzyrdyNCTsCg3RtzCGqD3Sq1QDK9s6/preview"
            title="YouTube video player"
            frameBorder="0"
            allowFullScreen
            className=" w-[40vw] max-w-5xl"
            style={{
              height: `${(40 / 16) * 9}vw`, // Set the height based on 16:9 ratio
              maxHeight: "27rem", // Limit the maximum height
            }}
          ></iframe>
          <div className="w-[40vw] max-w-5xl bg-black flex justify-center items-center gap-4 py-4">
            <div className=" bg-white rounded-full w-5 h-5 md:w-14 md:h-14 lg:w-16 lg:h-16"></div>
            <div className=" bg-white rounded-full w-5 h-5 md:w-14 md:h-14 lg:w-16 lg:h-16"></div>
            <div className=" bg-white rounded-full w-5 h-5 md:w-14 md:h-14 lg:w-16 lg:h-16"></div>
            <div className=" bg-red-600 rounded-full w-5 h-5 md:w-14 md:h-14 lg:w-16 lg:h-16"></div>
          </div>
        </div>
        <div className=" max-h-[400px] md:max-h-[550px] overflow-y-scroll">
          <div className=" ml-6 flex flex-col mr-4">
            <div className="flex">
              <img
                src={companyLogo}
                alt={`Logo of company`}
                className="mr-6 h-12 w-12 md:h-[5rem] md:w-[5rem]"
              />
              <div>
                <h1 className="font-clash-display-semibold text-base md:text-xl m-0 p-0 leading-5">
                  Ewar Games
                </h1>
                <h2 className="font-clash-display-medium opacity-70 text-xs md:text-lg m-0 p-0 leading-5">
                  Position: Game Designer
                </h2>
              </div>
            </div>

            <div>
              <div
                className="md:max-w-[90%] mt-4 md:ml-16 mr:8 bg-white rounded-2xl border-4 border-black p-6 space-y-2"
                style={{ boxShadow: "3px -3px 0px 0px black" }}
              >
                <p className="text-xs md:text-xl lg:text-2xl font-bold  font-clash-display-semibold m-0 p-0 lg:leading-5">
                   He worked on the following features:
                </p>
                <ul>
                  <li className="text-xs md:text-xl lg:text-2xl font-clash-display-semibold">• User Flow to enrich user experience</li>
                  <li className="text-xs md:text-xl lg:text-2xl font-clash-display-semibold">• Enhanced Game Loop for faster gameplay</li>
                  <li className="text-xs md:text-xl lg:text-2xl font-clash-display-semibold">• Updated Design Language and UI</li>
                  <li className="text-xs md:text-xl lg:text-2xl font-clash-display-semibold">• Gameplay Feel and Optimisation</li>
                  <li className="text-xs md:text-xl lg:text-2xl font-clash-display-semibold">• Design Language and UI</li>
                  <li className="text-xs md:text-xl lg:text-2xl font-clash-display-semibold">• Player Celebrations</li>
                </ul>
              </div>
              <p className="text-right mt-4 mr-20 lg:mr-40 font-clash-display-medium text-xs md:text-lg opacity-70">
                From April 2021 to March 2022
              </p>
            </div>
          </div>
          <div className=" mt-4 ml-6 flex flex-col mr-4">
            <div className="flex">
              <img
                src={avatarLogo}
                alt={`Avatar`}
                className="mr-6 h-12 w-12 md:h-[5rem] md:w-[5rem]"
              />
              <div>
                <h1 className=" font-clash-display-semibold text-sm md:text-xl m-0 p-0 leading-5">
                  Arinjay Choudhury
                </h1>
                <h2 className=" font-clash-display-medium opacity-70 text-xs md:text-lg m-0 p-0 leading-5">
                  Position: Game Designer
                </h2>
              </div>
            </div>

            <div>
              <div
                className="md:max-w-[40%] mt-4 md:ml-16 mr:8 bg-fgGreen rounded-2xl border-4 border-black p-6 space-y-2"
                style={{ boxShadow: "3px -3px 0px 0px black" }}
              >
                <p className=" ml-4 font-clash-display-semibold text-sm md:text-2xl">
                  {" "}
                  The Board Design !
                </p>
                <div
                  className="bg-black rounded-2xl border-2 border-black m-auto w-fit h-[30vh] md:h-[50vh]"
                  style={{ boxShadow: "4px 4px 0px 0px black" }}
                >
                  <img src={o1} className=" w-full h-full max-w-full max-h-full rounded-2xl"></img>
                </div>
              </div>
            </div>
            <div>
              <div
                className="md:max-w-[40%] mt-4 md:ml-16 mr:8 bg-fgGreen rounded-2xl border-4 border-black p-6 space-y-2"
                style={{ boxShadow: "3px -3px 0px 0px black" }}
              >
                <p className=" ml-4 font-clash-display-semibold text-sm md:text-2xl">
                  {" "}
                  Here is the Overhauled Design !
                </p>
                <div
                  className="bg-black rounded-2xl border-2 border-black m-auto w-fit h-[30vh] md:h-[50vh]"
                  style={{ boxShadow: "4px 4px 0px 0px black" }}
                >
                  <img src={o3} className=" w-full h-full max-w-full max-h-full rounded-2xl"></img>
                </div>
                <div
                  className="bg-black rounded-2xl border-2 border-black m-auto w-fit h-[30vh] md:h-[50vh]"
                  style={{ boxShadow: "4px 4px 0px 0px black" }}
                >
                  <img src={o2} className=" w-full h-full max-w-full max-h-full rounded-2xl"></img>
                </div>
              </div>
              <div
                className="md:max-w-[50%] mt-4 md:ml-16 mr:8 bg-fgGreen rounded-2xl border-4 border-black p-6 space-y-2"
                style={{ boxShadow: "3px -3px 0px 0px black" }}
              >
                <p className=" ml-4 font-clash-display-semibold text-sm md:text-2xl">
                  {" "}
                  Here is the updated Game Design !
                </p>
                <div className="rounded-2xl border-2 border-black" style={{ boxShadow: "4px 4px 0px 0px black" }}>
                  <iframe
                    frameBorder="0"
                    className=" w-full h-[50vh] rounded-2xl"
                    src="https://drive.google.com/file/d/1JsJCsU6LeHgXROTjkomIyy2Fslh-rYVz/preview"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Comment section and MyContributions Box */}
        <div className="flex justify-normal gap-5 p-2 mt-8">
          <div className={`bg-fgPurple2 rounded-2xl py-4 px-12 w-[100vw]`}>
            <h2
              className={`text-white text-xl md:text-xl font-clash-display-semibold`}
            ></h2>
          </div>

          <Link smooth to="/#my-works-section">
          <div
            className="inline-block bg-fgYellow rounded-2xl shadow-md border-2 md:border-4 border-black p-2 md:py-4 md:px-12"
            style={{ boxShadow: "-3px 3px 0px 0px black" }}
          >
          
            <h2 className=" md:whitespace-nowrap text-xs md:text-3xl text-center font-clash-display-semibold">
              My Contribution
            </h2> 
          </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Contribution6;
