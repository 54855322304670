import React, { useState } from "react";
import companyLogo from "../../assets/ewarLogo.jpg";
import avatarLogo from "../../assets/avatar.png";
import {HiChevronLeft,HiChevronRight} from 'react-icons/hi';
import { HashLink as Link } from 'react-router-hash-link';
import s1 from "../../assets/contr4/C4S1.png"
import o1 from "../../assets/contr4/C4O1.png"

const Contribution4 = () => {
  const [imageIndex, setImageIndex] = useState(0); // State to track the current image index

  const images = [s1]; // Array of image sources for the slideshow

  const nextImage = () => {
    setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="p-4 mt-8">
      <div
        className=" max-w-max md:max-w-[115rem] mx-auto bg-fgPurple rounded-2xl border-4 border-black p-6"
        style={{ boxShadow: "5px -5px 0px 0px black" }}
        id="experience-section"
      >
        <div className="bg-fgPurple2 flex flex-col items-center justify-center rounded-t-2xl mb-4">
          {/* Replaced the iframe with the image slideshow */}
          <div className=" flex justify-start gap-3 md:gap-4">
            <button className=" text-xs md:text-base" onClick={prevImage}><HiChevronLeft size={40}/></button>
            <div className="w-[40vw] max-w-5xl bg-black py-4">
              <img
                src={images[imageIndex]}
                alt="Slideshow"
                className="w-full max-h-[40vh] flex-none object-contain mt-4"
              />
            </div>
            <button className=" text-xs md:text-base" onClick={nextImage}><HiChevronRight size={40}/></button>
          </div>
          <div className="w-[40vw] max-w-5xl bg-black flex justify-center items-center gap-4 py-4">
            <div className=" bg-white rounded-full w-5 h-5 md:w-14 md:h-14 lg:w-16 lg:h-16"></div>
            <div className=" bg-white rounded-full w-5 h-5 md:w-14 md:h-14 lg:w-16 lg:h-16"></div>
            <div className=" bg-white rounded-full w-5 h-5 md:w-14 md:h-14 lg:w-16 lg:h-16"></div>
            <div className=" bg-red-600 rounded-full w-5 h-5 md:w-14 md:h-14 lg:w-16 lg:h-16"></div>
          </div>
        </div>
        <div className=" max-h-[400px] md:max-h-[550px] overflow-y-scroll">
          <div className=" ml-6 flex flex-col mr-4">
            <div className="flex">
              <img
                src={companyLogo}
                alt={`Logo of company`}
                className="mr-6 h-12 w-12 md:h-[5rem] md:w-[5rem] rounded-full border-4 border-black"
              />
              <div>
                <h1 className="font-clash-display-semibold text-base md:text-xl m-0 p-0 leading-5">
                  Ewar Games
                </h1>
                <h2 className="font-clash-display-medium opacity-70 text-xs md:text-lg m-0 p-0 leading-5">
                  Position: Game Designer
                </h2>
              </div>
            </div>

            <div>
              <div
                className="md:max-w-[90%] mt-4 md:ml-16 mr:8 bg-white rounded-2xl border-4 border-black p-6 space-y-2"
                style={{ boxShadow: "3px -3px 0px 0px black" }}
              >
                <p className="text-xs md:text-xl lg:text-2xl font-bold  font-clash-display-semibold m-0 p-0 lg:leading-5">
                   He worked on the following features:
                </p>
                <ul>
                  <li className="text-xs md:text-xl lg:text-2xl font-clash-display-semibold">• User Flow</li>
                  <li className="text-xs md:text-xl lg:text-2xl font-clash-display-semibold">• Game Loop</li>
                  <li className="text-xs md:text-xl lg:text-2xl font-clash-display-semibold">• Scoring Factors - Hits and misses</li>
                  <li className="text-xs md:text-xl lg:text-2xl font-clash-display-semibold">• PvP Interaction</li>
                  <li className="text-xs md:text-xl lg:text-2xl font-clash-display-semibold">• Design Language and UI</li>
                  <li className="text-xs md:text-xl lg:text-2xl font-clash-display-semibold">• Gameplay Feel and Optimisation</li>
                  <li className="text-xs md:text-xl lg:text-2xl font-clash-display-semibold">• Player Celebrations.</li>
                </ul>
              </div>
              <p className="text-right mt-4 mr-20 lg:mr-40 font-clash-display-medium text-xs md:text-lg opacity-70">
                From April 2021 to March 2022
              </p>
            </div>
          </div>
          <div className=" mt-4 ml-6 flex flex-col mr-4">
            <div className="flex">
              <img
                src={avatarLogo}
                alt={`Avatar`}
                className="mr-6 h-12 w-12 md:h-[5rem] md:w-[5rem]"
              />
              <div>
                <h1 className=" font-clash-display-semibold text-sm md:text-xl m-0 p-0 leading-5">
                  Arinjay Choudhury
                </h1>
                <h2 className=" font-clash-display-medium opacity-70 text-xs md:text-lg m-0 p-0 leading-5">
                  Position: Game Designer
                </h2>
              </div>
            </div>

            <div>
              <div
                className="md:max-w-[50%] mt-4 md:ml-16 mr:8 bg-fgGreen rounded-2xl border-4 border-black p-6 space-y-2"
                style={{ boxShadow: "3px -3px 0px 0px black" }}
              >
                <p className=" ml-4 font-clash-display-semibold text-sm md:text-2xl">
                  {" "}
                  This is the Final Game!
                </p>
                <div
                  className="bg-black rounded-2xl border-2 border-black m-auto w-fit h-[30vh] md:h-[50vh]"
                  style={{ boxShadow: "4px 4px 0px 0px black" }}
                >
                  <img src={o1} className=" w-full h-full max-w-full max-h-full rounded-2xl"></img>
                </div>
              </div>  
            </div>
          </div>
        </div>
        {/* Comment section and MyContributions Box */}
        <div className="flex justify-normal gap-5 p-2 mt-8">
          <div className={`bg-fgPurple2 rounded-2xl py-4 px-12 w-[100vw]`}>
            <h2
              className={`text-white text-xl md:text-xl font-clash-display-semibold`}
            ></h2>
          </div>
          <Link smooth to="/#my-works-section">
          <div
            className="inline-block bg-fgYellow rounded-2xl shadow-md border-2 md:border-4 border-black p-2 md:py-4 md:px-12"
            style={{ boxShadow: "-3px 3px 0px 0px black" }}
          >
          
            <h2 className=" md:whitespace-nowrap text-xs md:text-3xl text-center font-clash-display-semibold">
              My Contribution
            </h2> 
          </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Contribution4;
