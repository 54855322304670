import React from "react";
import avatarImage from "../assets/avatar.png";
import linkedInIco from "../assets/linkedIn.png";
import mailIco from "../assets/mail.png";
import resumeIco from "../assets/resume.png";

const HeroSection = () => {
  return (
    <>
      <div className="flex flex-row justify-around items-center py-8 mt-24">
        <div className="flex flex-col mr-8 ml-4" id="hero-section">
          <h1 className="text-4xl md:text-6xl lg:text-8xl font-clash-display-bold tracking-tight font-black text-fgPurple text-stroke-sm text-stroke-lg select-none">
            ARINJAY
          </h1>
          <h2 className="text-4xl md:text-6xl lg:text-8xl ml-0 md:ml-28 font-clash-display-bold tracking-tight font-black text-fgPurple text-stroke-sm text-stroke-lg select-none">
            CHOUDHURY
          </h2>
          <h3 className="text-4xl md:text-6xl lg:text-8xl mt-8 font-clash-display-bold tracking-tight font-black text-fgYellow text-drop-shadow text-stroke-sm text-stroke-lg select-none">
            GAME DESIGNER
          </h3>
          <div className="flex items-center space-x-8">
            <a href="https://www.linkedin.com/in/arinjayc/" target="_blank" >
            <img
              src={linkedInIco}
              alt="linkedin"
              className="w-12 h-12 md:w-auto md:h-auto"
            />
            </a>
            <a href="https://meet.arinjaychoudhury.com" target="_blank" >            
            <img
              src={mailIco}
              alt="email"
              className="w-12 h-12 md:w-auto md:h-20"
            />
            </a>
            <a href="https://drive.google.com/file/d/1sXSKTuRZ6kaVOh_uFrW776yAn0jHh5nd/preview" target="_blank" >
            <img
              src={resumeIco}
              alt="resume"
              className="w-12 h-12 md:w-auto md:h-20"
            />
            </a>
          </div>
        </div>
        <div className="w-full md:w-auto mt-8 md:mt-0 ">
          <img
            src={avatarImage}
            alt="Hero Image"
            className="w-full md:w-auto object-contain h-48 md:h-auto"
          />
        </div>
      </div>
    </>
  );
};

export default HeroSection;
